export const BKASH_B = 'bkash_b';
export const NAGAD_B = 'nagad_b';
export const UPAY = 'upay';
export const D17_L = 'd17_l';
export const PHONEPE = 'phonepe';
export const UPI_P2P = 'upi_p2p';
export const PAYTM = 'paytm';
export const UPI_FAST_V = 'upi_fast_v';
export const IMPS = 'imps';

export const BANK_AL_FALAH = 'bankalfalah';
export const EASY_PAISA = 'easypaisa';
export const EASY_PAISA_L = 'easypaisa_l';
export const ESEWA = 'esewa';
export const JAZZ_CASH_FAST = 'jazzcash_fast';
export const JAZZ_CASH_L = 'jazzcash_l';
export const KHALTI = 'khalti';
export const NAYA_PAY_L = 'nayapay_l';

export const PHONE_NUMBER_REGEXP = /^254[0-9]{9}$/;
export const PHONE_NUMBER_WITHOUT_CODE_REGEXP = /^[0-9]{10}$/;
export const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const ACCOUNT_NUMBER_LENGTH = 11;
export const AMOUNT_FIELD_NAME = 'amount';

export const TYPE_DROPDOWN = 'dropdown';

export const DEPOSIT_CONFIG = {
  [BKASH_B]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
  },
  [NAGAD_B]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [UPAY]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [D17_L]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [PHONEPE]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [UPI_P2P]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [PAYTM]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [UPI_FAST_V]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [IMPS]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [JAZZ_CASH_FAST]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
  },
  [BANK_AL_FALAH]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [EASY_PAISA]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [EASY_PAISA_L]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [ESEWA]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [JAZZ_CASH_L]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [KHALTI]: {
    [AMOUNT_FIELD_NAME]: {},
  },
  [NAYA_PAY_L]: {
    [AMOUNT_FIELD_NAME]: {},
  },
};

export const WITHDRAW_CONFIG = {
  [BKASH_B]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {
      length: ACCOUNT_NUMBER_LENGTH,
    },
    account_email: {
      regexp: EMAIL_REGEXP,
    },
  },
  [NAGAD_B]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
  },
  [UPAY]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
  },
  [D17_L]: {
    [AMOUNT_FIELD_NAME]: {},
    phone_number: {
      regexp: PHONE_NUMBER_REGEXP,
    },
  },
  [PHONEPE]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
  },
  [UPI_P2P]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
    account_email: {
      regexp: EMAIL_REGEXP,
    },
  },
  [PAYTM]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
  },
  [UPI_FAST_V]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
  },
  [IMPS]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
    account_name: {},
    bank_code: {},
  },
  [NAYA_PAY_L]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
    account_name: {},
  },
  [KHALTI]: {
    [AMOUNT_FIELD_NAME]: {},
    phone_number: {
      regexp: PHONE_NUMBER_WITHOUT_CODE_REGEXP,
    },
  },
  [ESEWA]: {
    [AMOUNT_FIELD_NAME]: {},
    phone_number: {
      regexp: PHONE_NUMBER_WITHOUT_CODE_REGEXP,
    },
  },
  [JAZZ_CASH_L]: {
    [AMOUNT_FIELD_NAME]: {},
    account_number: {},
    account_name: {},
  },
  [EASY_PAISA]: {
    [AMOUNT_FIELD_NAME]: {},
    wallet_type: {
      type: TYPE_DROPDOWN,
      options: [
        {
          name: 'easypaisa',
          id: 'easypaisa',
        },
        {
          name: 'jazzcash',
          id: 'jazzcash',
        },
      ],
    },
    account_name: {},
    account_number: {},
  },
  [BANK_AL_FALAH]: {
    [AMOUNT_FIELD_NAME]: {},
    account_title: {},
    account_number: {},
    iban_number: {},
    customer_phone: {},
  },
};
