import _Request from '../Request';

interface ISetCookie {
  name: string;
  value: string;
  options: {
    domain: string;
    httpOnly: boolean;
    maxAge: number;
    secure: boolean;
  };
}

type SportUrlResponseType = {
  brandId?: string;
  themeName?: string;
  token?: string;
  url?: string;
  cookies?: ISetCookie[];
};

type SportUrlType = Record<number, SportUrlResponseType>;

class SportService extends _Request {
  constructor() {
    super('');
  }

  async getSportUrl(sportId: number): Promise<SportUrlType> {
    const result: SportUrlResponseType = await this.send({ method: 'get' }, `/sport/game-url?sportId=${sportId}`);

    (result.cookies || []).forEach((cookie: ISetCookie) => {
      const domain = window.location.hostname.split('.').slice(-2).join('.');
      document.cookie = `${cookie.name}=${cookie.value}; domain=.${domain}; path=/; secure=true;`;
    });

    return { [sportId]: result };
  }

  async withdraw(sportId: number): Promise<void> {
    await this.send({ data: { sportId }, method: 'post' }, '/sport/withdraw');
  }
}

export default new SportService();
