import { Stack, Typography } from '@mui/material';

import HomeIcon from 'components/common/icons/Home';
import PromotionsIcon from 'components/common/icons/Promotions';
import AndroidIcon from 'components/common/icons/Android';
import AffiliateIcon from 'components/common/icons/Affiliate';

import Actions from 'components/sections/Navigation/Actions';

import useSettings, { IWebsiteConfigs } from 'store/settings';

import { FC } from 'react';
import useTranslates from 'utils/translate';
import CategoryLinks from '../CategoryLinks';
import MenuItem, { MenuItemWithoutLink } from '../MenuItem';
import SportLinks from '../SportLinks';

interface IDefaultMobileMenuProps {
  onMenuClose: () => void;
}

const DefaultMobileMenu: FC<IDefaultMobileMenuProps> = ({ onMenuClose }) => {
  const { translate } = useTranslates();

  const [
    {
      data: { meta = {} as IWebsiteConfigs },
    },
  ] = useSettings();

  return (
    <Stack width="100%" gap={1} px={2}>
      <Stack width="100%" direction="row" gap={1}>
        {meta?.isHomepageActive && (
          <MenuItem to="/" icon={<HomeIcon color="primary" />} onClick={onMenuClose}>
            {translate('home')}
          </MenuItem>
        )}
        <MenuItem to="/promotions" icon={<PromotionsIcon color="primary" />} onClick={onMenuClose}>
          {translate('promotions')}
        </MenuItem>
      </Stack>
      <Typography>{translate('sport')}</Typography>

      <SportLinks onItemClick={onMenuClose} />

      <Typography>{translate('menu_casino')}</Typography>

      <CategoryLinks onItemClick={onMenuClose} />

      <Typography>{translate('other')}</Typography>

      {!!meta.affiliateUrl && (
        <MenuItem to={meta.affiliateUrl} icon={<AffiliateIcon color="primary" />} onClick={onMenuClose}>
          {translate('affiliate')}
        </MenuItem>
      )}
      {!!meta.androidAppUrl && (
        <MenuItemWithoutLink icon={<AndroidIcon color="primary" />} onClick={onMenuClose}>
          <a href={meta.androidAppUrl} download={window.location.host}>
            {translate('android_application')}
          </a>
        </MenuItemWithoutLink>
      )}
      <Stack sx={{ m: '24px auto !important' }} direction="row" gap={3}>
        <Actions />
      </Stack>
    </Stack>
  );
};

export default DefaultMobileMenu;
