import useMount from 'hooks/useMount';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import useTranslates from 'utils/translate';
import { AMOUNT_FIELD_NAME, WITHDRAW_CONFIG, TYPE_DROPDOWN } from './constants';
import { Grid } from '@mui/material';
import FormInput from 'components/common/Form/Input';
import FormSelect from 'components/common/Form/Select';
import FormLabel from 'components/common/Form/FormLabel';

const Withdraw = ({
  control,
  watch,
  setError,
  setValue,
  paymentMethod,
  changeActionButtonVisibility,
  changeAmountFieldVisibility,
  changeActionButtonAvailability,
}) => {
  const dropdownInit = useRef(false);
  const configuration = WITHDRAW_CONFIG[paymentMethod.method.name];
  const originalFieldNames = useMemo(() => Object.keys(configuration || {}), [configuration]);
  const fieldNames = useMemo(
    () => originalFieldNames.filter((fieldName) => fieldName !== AMOUNT_FIELD_NAME),
    [configuration]
  );
  const { translate } = useTranslates();

  useMount(() => {
    changeAmountFieldVisibility(originalFieldNames.includes(AMOUNT_FIELD_NAME));
    changeActionButtonVisibility(true);
    changeActionButtonAvailability(false);
  });

  const validateField = useCallback((fieldName: string) => {
    const fieldValue = watch(fieldName) || '';
    const validators = configuration[fieldName];

    const validByLength = !validators?.length || fieldValue.length === validators.length;
    const validByRegexp = !validators?.regexp || validators.regexp.test(fieldValue);
    return validByLength && validByRegexp && Boolean(fieldValue);
  }, []);

  const onFieldBlur = (fieldName: string) => {
    if (validateField(fieldName)) {
      setError(fieldName, null);
    } else {
      setError(fieldName, { message: translate(`invalid_${fieldName}`) });
    }
  };

  const areAllFieldsFilled = originalFieldNames.every(validateField);

  useEffect(() => {
    changeActionButtonAvailability(areAllFieldsFilled);
  }, [areAllFieldsFilled]);

  useEffect(() => {
    if (dropdownInit.current || !fieldNames) return;

    fieldNames.forEach((name) => {
      if (configuration[name]?.type === TYPE_DROPDOWN) {
        const fieldValue = watch(name) || '';
        if (!fieldValue) {
          setValue(name, configuration[name]?.options?.[0]?.id);
        }
      }
    });

    dropdownInit.current = true;
  }, [configuration, fieldNames]);

  return (
    <>
      {fieldNames.map((fieldName) => (
        <Grid item xs={24} key={fieldName}>
          {configuration[fieldName]?.type === TYPE_DROPDOWN ? (
            <>
              <FormLabel>{translate(fieldName)}</FormLabel>
              <FormSelect
                onBlur={() => onFieldBlur(fieldName)}
                label={translate(fieldName)}
                name={fieldName}
                required
                control={control}
                options={configuration[fieldName]?.options || []}
              />
            </>
          ) : (
            <FormInput
              onBlur={() => onFieldBlur(fieldName)}
              label={translate(fieldName)}
              name={fieldName}
              required
              control={control}
            />
          )}
        </Grid>
      ))}
    </>
  );
};

export default Withdraw;
